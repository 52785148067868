const pollCheckout = async (eventID) => {
	try {
		const res = await fetch(`https://hoc-api.apollodashboards.io/api/subscriptions/processing?eventID=${eventID}`, {
	        method: "GET",
	        headers: {
	          Authorization: "Basic MTA2NTQ2ODkzMTcwOTU5OTc0NDpiZXN0ZGFzaA==",
	        }
	    });

      if(!res.ok) {
        return false;
      }
  
      let result = await res.json();
  
      if (result.error) {
        return false;
      }

      return result.status;
    } catch (error) {
      return false;
    }
};

export default pollCheckout;