import {
  Avatar,
  Text,
  Flex,
  Button,
  Heading,
  Input,
  Select,
} from "@chakra-ui/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Router from "next/router";
import React, { useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useSession from "../../../hooks/useSession";
import checkout from "./checkout";
import pollCheckout from "./pollCheckout";
import { toast } from "react-toastify";
import Link from "next/link";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import CountryRegionData from "country-region-data";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const BouncingDots = () => {
  return (
    <>
      <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
};

const StateSelector = ({ country, updateState }) => {
  // current country data
  const c = CountryRegionData.find(
    (cdata) => cdata.countryShortCode === country,
  ) || { regions: [] };

  return (
    <Flex flexDirection="column" pb={4}>
      <Select
        placeholder="State"
        border="2px"
        borderColor="#708090"
        borderRadius="10px"
        onChange={(e) => updateState(e.target.value)}
      >
        {c.regions.map((state) => (
          <option value={state.shortCode}>{state.name}</option>
        ))}
      </Select>
    </Flex>
  );
};

const PurchaseForm = (props) => {
  const { session } = useSession();
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMsg] = useState("");
  const [fullName, setFullName] = useState("");
  const [discount, setDiscount] = useState("");
  const [price, setPrice] = useState(
    props.product.price > 100 ? props.product.price / 100 : props.product.price,
  );
  const [loadingPurchaseConfirmation, setLoadingPurchaseConfirmation] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  console.log(session, loadingPurchaseConfirmation);

  useEffect(() => {
    if (!session) {
      Router.push("/");
    }
  }, []);

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#302e66",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#302e66" },
        "::placeholder": { color: "#302e66" },
      },
      disabled: loadingPurchaseConfirmation,
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };

  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loadingPurchaseConfirmation) {
      return;
    }
    setLoadingPurchaseConfirmation(true);

    console.log("e");

    if (!stripe || !elements) {
      return setLoadingPurchaseConfirmation(false);
    }

    if (!fullName) {
      toast.error("Please fill out your name");
      setLoadingPurchaseConfirmation(false);
      return;
    }

    // for (const addressUnit of Object.keys(billingAddress)) {
    //   if (billingAddress[addressUnit] === "") {
    //     const cleanedName =
    //       addressUnit == "line1" ? "Street Address" : addressUnit;
    //     toast.error(`Please fill out your ${cleanedName}`);
    //     return;
    //   }
    // }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: fullName,
        email: session.user.email,
      },
    });

    if (error) {
      console.log(error);
      setError(error.message);
      return setLoadingPurchaseConfirmation(false);
      setLoading(false);
    } else {
      setError(null);
      setLoading(true);
      try {
        const submitCheckout = await checkout(
          paymentMethod.id,
          fullName,
          session.user.email,
          props.product.priceID,
          props.product.productID,
          "subscription",
          session.user.id,
          session.token,
          discount
            ? discount
            : props.checkout && props.checkout.discount
              ? props.checkout.discount.id
              : null,
          props.password,
          props.checkout ? props.checkout._id : null,
          props.release ? props.release._id : null,
          props.password,
        );

        if (!submitCheckout) {
          throw "Could not submit checkout";
        }

        let polling = true;
        let success = false;
        while (polling) {
          const resp = await pollCheckout(submitCheckout);
          if (resp !== "queued") {
            if (resp == "SUCCESSFUL") {
              success = true;
            }
            break;
          }

          await sleep(3000);
        }

        if (!success) {
          throw "unsuccessful";
        }

        toast.success(`Succcessfully subscribed to: ${props.product.name}`);
        setLoadingPurchaseConfirmation(false);
        setLoading(false);
        window.location = "/dashboard";
      } catch (error) {
        console.log(error);
        setErrorMsg(
          "Unfortunately you missed out on this opportunity, please check our twitter to stay updated on the next opportunity to purchase",
        );
        setLoadingPurchaseConfirmation(false);
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      isOpen={props.purchaseFormModal}
      toggle={() => props.toggle(true)}
      backdropClassName={"pe-backdrop"}
    >
      <ModalBody>
        {errorMessage == "" ? (
          <>
            {!loading ? (
              <>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Flex
                    alignSelf="center"
                    alignItems="center"
                    ml={"25%"}
                    mb={4}
                    flexDirection="column"
                  >
                    <Avatar
                      size="md"
                      name={session.user?.name}
                      src={session.user?.image}
                      boxShadow="xl"
                    />
                    <Heading fontSize="2xl">Price Errors</Heading>
                    <Text
                      fontSize="md"
                      fontWeight="bold"
                      textDecoration={
                        props.checkout?.discount || discount
                          ? "line-through"
                          : "none"
                      }
                    >
                      ${price.toFixed(2)}/month
                    </Text>
                    {props.checkout && props.checkout.discount && (
                      <Text fontSize="md" fontWeight="bold">
                        ${" "}
                        {props.checkout.discount.type == "percentage"
                          ? (
                              price -
                              price * (props.checkout.discount.amount / 100)
                            ).toFixed(2)
                          : (price - props.checkout.discount.amount).toFixed(
                              2,
                            )}{" "}
                        / month
                      </Text>
                    )}
                    {props.checkout && props.checkout.trialLength > 0 && (
                      <Text fontSize="md" fontWeight="bold">
                        {props.checkout.trialLength / 86400000} Day Free Trial
                      </Text>
                    )}
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    pl={"10%"}
                    flexDirection="row"
                  >
                    <Button onClick={props.toggle} variant="link">
                      x
                    </Button>
                  </Flex>
                </Flex>
                <FormControl>
                  <Flex m={8} flexDirection="column">
                    <Flex flexDirection="column" pb={4}>
                      <Input
                        placeholder="Full Name"
                        border="2px"
                        borderColor="#708090"
                        borderRadius="10px"
                        isDisabled={loadingPurchaseConfirmation}
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </Flex>

                    <Flex
                      p={2}
                      alignItems="center"
                      border="2px solid #708090"
                      borderRadius="10px"
                      mb={4}
                      flexDirection="column"
                    >
                      <CardElement
                        id="card-element"
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleChange}
                      />
                      <Text color="red.500" bgColor="aliceblue">
                        {error}
                      </Text>
                    </Flex>

                    <Flex flexDirection="column" pb={4}>
                      {props.checkout && props.checkout.discount ? (
                        <Text>Discount: {props.checkout.discount.id}</Text>
                      ) : (
                        <Input
                          placeholder="Discount Code"
                          border="2px"
                          borderColor="#708090"
                          borderRadius="10px"
                          isDisabled={loadingPurchaseConfirmation}
                          onChange={(e) => setDiscount(e.target.value)}
                        />
                      )}
                    </Flex>
                  </Flex>
                </FormControl>
                <Flex flexDirection="column">
                  <Flex
                    justifyContent="center"
                    textAlign="center"
                    flexDirection="column"
                  >
                    <Text fontSize="sm" color="#302e66">
                      This is a recurring subscription, payments are managed by
                      Stripe
                    </Text>
                    <Text fontSize="sm" color="#302e66">
                      By purchasing you agree to these{" "}
                      <Link href={"/policies/tos"}>Terms of service</Link>
                    </Text>
                  </Flex>
                  {loadingPurchaseConfirmation ? (
                    <Button
                      isLoading
                      loadingText="Processing"
                      variant="outline"
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      fontSize="lg"
                      bgColor="#302e66"
                      bg="transparent"
                      color="#e2dff4"
                      backgroundSize="200% auto"
                      border="2px"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <Flex>
                        <span>
                          <i className="fas fa-dollar-sign"></i>
                        </span>
                        <Text pl={2}>Pay</Text>
                      </Flex>
                    </Button>
                  )}
                </Flex>
              </>
            ) : (
              <>
                <Flex>
                  <BouncingDots />
                  <br />
                  <Text fontSize="lg">You are in Queue to Purchase</Text>
                </Flex>
              </>
            )}
          </>
        ) : (
          <>
            <Flex>
              <Text fontSize="lg">
                Unfortunately you missed out on this opportunity, please check
                our <a href="https://twitter.com/PriceErrors"></a> to stay
                updated on the next opportunity to purchase
              </Text>
            </Flex>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PurchaseForm;
