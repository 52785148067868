
const checkout = async (
    paymentId,
    name,
    email,
    priceId,
    productID,
    type,
    discord,
    jwt,
    discount,
    password,
    checkoutId,
    releaseId,
    errorOverride = true,
  ) => {
    try {
      const res = await fetch(`https://hoc-api.apollodashboards.io/api/subscriptions/create`, {
        method: "POST",
        headers: {
          Authorization: "Basic MTA2NTQ2ODkzMTcwOTU5OTc0NDpiZXN0ZGFzaA==",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentID: paymentId,
          name,
          email,
          priceID: priceId,
          planID: productID,
          type,
          discord,
          session: jwt,
          discount,
          password,
          checkoutID: checkoutId,
          releaseID: releaseId,
          errorOverride,
        }),
      });

      if(!res.ok) {
        return false;
      }
  
      let result = await res.json();
  
      if (result.error) {
        return false;
      }

      return result.eventID;
    } catch (error) {
      return false;
    }
  };
  
  export default checkout;